import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'flag-icon-css/css/flag-icons.min.css'
import App from './App';
import axios from 'axios';
import Cookies from 'js-cookie';


const root = ReactDOM.createRoot(document.getElementById('root'));
axios.interceptors.response.use(response => {
  return response;
}, error => {
  console.log('inside error', error);
  if (error.response.status == 401) {
    Cookies.remove('moToken')
    window.location.replace('/')
    //place your reentry code
  }
  return error;
});
const loadingMarkup = (
  <div className='py-4 text-center' >
    <h2>Loading...</h2>
  </div>
)
root.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
