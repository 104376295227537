import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "../../styles/navStyles/SideBar.module.css";

const TestSideNav = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState();
  const [active, setActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setSelected(window.location.hash);
  }, [window.location.hash]);
  useEffect(() => {}, [selected]);

  return (
    <div className={`${style.navContainer}`}>
      <SideNav
        className={`mysidenav`}
        onSelect={(selected) => {
          navigate(selected);
          setSelected(selected);
        }}
        onClick={(e) => {
          if (selected == ref.current.props.selected) {
            setActive(true);
          }
        }}
      >
        <SideNav.Toggle />
        <SideNav.Nav defaultSelected={window.location.hash}>
          <NavItem eventKey="/choose-creation-way">
            <NavIcon>
              <div className={`${style.iconContainer}`}>
                {window.location.hash == "#/choose-creation-way" ? (
                  <img src="../white-dasboard.svg" alt="Dashboard " />
                ) : (
                  <img src="../dashboard.svg" alt="Dashboard " />
                )}
              </div>
            </NavIcon>
            <NavText>Dashboard</NavText>
          </NavItem>
          <NavItem eventKey="/campaign-section/">
            <NavIcon>
              <div className={`${style.iconContainer}`}>
                {window.location.hash === "#/campaign-section/1" ||
                window.location.hash === "#/campaign-section/2" ||
                window.location.hash === "#/campaign-section/3" ? (
                  <img src="../white-sms.svg" alt="Campaigns" />
                ) : (
                  <img src="../black-sms.svg" alt="Campaigns" />
                )}
              </div>
            </NavIcon>
            <NavText>Campaigns</NavText>
            <NavItem eventKey="/campaign-section/1">
              <NavIcon>
                <div className={`${style.iconContainer} px-1`}>
                  {window.location.hash === "#/campaign-section/1" ||
                  window.location.hash === "#/campaign-section/2" ||
                  window.location.hash === "#/campaign-section/3" ? (
                    <img src="../white-sms.svg" alt="Campaigns" />
                  ) : (
                    <img src="../black-sms.svg" alt="Campaigns" />
                  )}
                </div>
              </NavIcon>
              <NavText>Create Campaign</NavText>
            </NavItem>
            <NavItem eventKey="/campaign-section/2">
              <NavIcon>
                <div className={`${style.iconContainer} px-1`}>
                  <img src="../campaignHistory.svg" alt="Campaigns" />
                </div>
              </NavIcon>
              <NavText>Campaign History</NavText>
            </NavItem>
            <NavItem eventKey="/campaign-section/3">
              <NavIcon>
                <div className={`${style.iconContainer} px-1`}>
                  <img src="../black-sms.svg" alt="Campaigns" />
                </div>
              </NavIcon>
              <NavText>Scheduled Campaign</NavText>
            </NavItem>
          </NavItem>
          <NavItem eventKey="/list-section/2">
            <NavIcon>
              <div className={`${style.iconContainer}`}>
                {window.location.hash === "#/list-section/1" ||
                window.location.hash === "#/list-section/2" ? (
                  <img src="../white-contacts.svg" alt="Contacts " />
                ) : (
                  <img src="../contacts.svg" alt="Contacts " />
                )}
              </div>
            </NavIcon>
            <NavText>Contacts</NavText>
          </NavItem>
          {/* <NavItem eventKey="">
            <NavIcon>
              <div className={`${style.iconContainer}`}>
                {window.location.hash === "" ? (
                  <img src="../white-report.svg" alt="Contacts " />
                ) : (
                  <img src="../chart-area.svg" alt="Reports " />
                )}
              </div>
            </NavIcon>
            <NavText>Reports</NavText>
          </NavItem>
          <NavItem eventKey="">
            <NavIcon>
              <div className={`${style.iconContainer}`}>
                {window.location.hash === "" ? (
                  <img src="../white-help.svg" alt="Contacts " />
                ) : (
                  <img src="../help.svg" alt="Help " />
                )}
              </div>
            </NavIcon>
            <NavText>Help</NavText>
          </NavItem> */}
        </SideNav.Nav>
      </SideNav>
    </div>
  );
};

export default TestSideNav;
