import React, { useEffect } from "react";
import style from "../styles/pageStyle/ControllerStyle.module.css";
import { useDispatch } from "react-redux";
import { getAllCampaigns } from "../state/allCampaigns/getAllCampaignsAction";
import { getQuota } from "../state/quota/getQuotaAction";
import { deleteListsIds } from "../state/deleteList/deleteListAction";
import { getListData } from "../state/listData/listAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Controller = ({
  history,
  historyData,
  setHistoryData,
  campaignsData,
  pageCount,
  listIDS,
}) => {
  const MySwal = withReactContent(Swal);

  const dispatch = useDispatch();
  const handelSearch = (e) => {
    let query = e.target.value;
    let x = historyData?.filter((data) =>
      history
        ? data.campaigName.toLowerCase().indexOf(query.toLowerCase()) !== -1
        : data.collectionName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (e.target.value != "") {
      setHistoryData(x);
    } else {
      setHistoryData(campaignsData);
    }
  };

  const handelRefresh = () => {
    let fromHistory = true;
    if (window.location.hash == "#/campaign-section/2") {
      dispatch(getAllCampaigns({ pageCount, fromHistory }));
    } else if (window.location.hash == "#/campaign-section/3") {
      fromHistory = false;
      dispatch(getAllCampaigns({ pageCount, fromHistory }));
    }
    dispatch(getQuota());
  };

  const handelDelete = () => {
    dispatch(deleteListsIds(listIDS)).then((res) => {
      MySwal.fire({
        icon: "success",
        text: res?.payload?.data,
      }).then(() => {
        dispatch(getListData(pageCount));
      });
    });
  };

  return (
    <div className={`d-flex justify-content-between mb-4 ${style.controlBar}`}>
      <div className={`col-5 d-flex search-bar ${style.search}`}>
        <input
          type="text"
          className="form-control"
          placeholder="Search with list name"
          onChange={handelSearch}
        />
        <div>
          <img src="../search.png" alt="search input icon" />
        </div>
      </div>
      <div className={`col-2 ${style.deleteBtn}`}>
        {history ? (
          <button
            type="button"
            className={`btn d-flex ${style.refreshBtn}`}
            onClick={handelRefresh}
          >
            <>
              <div className="mx-2">
                <img src="../refresh.png" alt="refresh button icon" />
              </div>
              Refresh
            </>
          </button>
        ) : (
          <button
            type="button"
            className={`btn d-flex ${style.refreshBtn}`}
            onClick={handelDelete}
          >
            <>
              <div className="mx-2">
                <img src="../delete.png" alt="delete button icon" />
              </div>
              Delete
            </>
          </button>
        )}
      </div>
    </div>
  );
};

export default Controller;
