import { configureStore } from '@reduxjs/toolkit'
import loginData from './login/loginSlice'
import packages from './packages/getPackagesSlice'
import listData from './listData/listSlice'
import senderData from './senderData/senderSlice'
import postCreateCampaign from './createCampagin/createCampaignSlice'
import campaignsData from './allCampaigns/getAllCampaignsSlice'
import singleCampaignData from './singleCampaign/getSingleCampaignSlice'
import packageTypeData from './packageType/packageTypeSlice'
import quotaData from './quota/getQuotaSlice'
import createCampaignData from './createCampagin/createCampaignSlice'
import createListData from './createList/createListSlice'

const store = configureStore({
    reducer: { loginData, packages, listData, postCreateCampaign, senderData, campaignsData, singleCampaignData, packageTypeData, quotaData, createCampaignData, createListData },
    devTools: true
})

export default store