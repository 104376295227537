import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./getQuotaAction";
const initialState = { quotaData: [], loading: false, error: null }

const getQuotaSlice = createSlice({
    name: 'quota',
    initialState,
    reducers: {},
    extraReducers
})

export default getQuotaSlice.reducer;