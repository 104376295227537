import React from "react";
import NavBar from "../Component/navBars/NavBar";
import Footer from "../Component/navBars/Footer";
import RechargeNav from "../Component/navBars/RechargeNav";
import style from "../styles/pageStyle/Success.module.css";
import { useNavigate } from "react-router-dom";
import { getListData } from "../state/listData/listAction";
import { useDispatch } from "react-redux";

const ListSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <NavBar />
      <section className={`d-flex justify-content-between`}>
        <div className="bg col-9 container">
          <div className="d-flex algin-content-center mt-5 pt-5 col-7 m-auto">
            <div className="text-center">
              <div className={`${style.successIcon}  m-auto mb-4`}>
                <img src="../createCampaign.svg" alt="" />
              </div>
              <div className={`${style.contentStyle}`}>
                <h1>List Created Successfully</h1>
                <p>
                  Your List have been created successfully. You can now check
                  your new List history
                </p>
              </div>
              <button
                type="button"
                className="btn btnBG mt-3 col-7"
                onClick={() => {
                  dispatch(getListData()).then(() => {
                    navigate("/list-section/2", { replace: true });
                    sessionStorage.setItem("activeList", 2);
                  });
                }}
              >
                View List
              </button>
            </div>
          </div>
        </div>
        <RechargeNav />
      </section>
      <Footer />
    </>
  );
};

export default ListSuccess;
