import './App.css';
import { createBrowserRouter, RouterProvider, HashRouter, createHashRouter } from 'react-router-dom'
import store from './state';
import { Provider } from 'react-redux';
import Login from './Page/Login';
import ErrorPage from './Page/ErrorPage';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import ChooseCreationWay from './Page/ChooseCreationWay';
import CampaignSuccess from './Page/CampaignSuccess';
import ListSuccess from './Page/ListSuccess';
import CreateCampaignContainer from './Page/CreateCampaignContainer';
import CreateListContainer from './Page/CreateListContainer';
import Packages from './Page/Packages';


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ['en', 'ar', 'fr'],
    fallbackLng: "en",
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
  });

function App() {
  const RoutesComponents = createHashRouter([
    {
      errorElement: <ErrorPage />,
      children: [
        { key: 1, path: "/", element: <Login />, exact: true },
        { key: 1, path: "/login", element: <Login />, exact: false },
        { key: 2, path: "/choose-creation-way", element: <ChooseCreationWay />, exact: false },
        { key: 3, path: "/campaign-success/:id", element: <CampaignSuccess />, exact: false },
        { key: 4, path: "/list-success", element: <ListSuccess />, exact: false },
        { key: 5, path: "/campaign-section/:id", element: <CreateCampaignContainer />, exact: false },
        { key: 6, path: "/list-section/:id", element: <CreateListContainer />, exact: false },
        { key: 6, path: "/packages", element: <Packages />, exact: false },
      ]
    }
  ])
  return (
    <Provider store={store}>
      <RouterProvider router={RoutesComponents} />
    </Provider>
  );
}

export default App;
