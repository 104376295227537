import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
    allHistoryCampaigns,
    allScheduledCampaigns
} from '../endpoints'
import Cookies from "js-cookie";

export const getAllCampaigns = createAsyncThunk('campaigns/getAllCampaigns', async (page) => {
    const { pageCount, fromHistory } = page
    const config = {
        method: 'get',
        url: fromHistory ? allHistoryCampaigns + pageCount : allScheduledCampaigns + pageCount,
        headers: {
            'Authorization': `Bearer ${Cookies.get('moToken')}`
        }
    }
    try {
        const response = axios(config).then((res) => {
            return res
        })
        return response
    } catch (error) {
        return error
    }
})

export const extraReducers = {
    [getAllCampaigns.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [getAllCampaigns.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.campaignsData = payload.data
    },
    [getAllCampaigns.rejected]: (state, { error }) => {
        state.loading = false
        state.error = error
    },
}