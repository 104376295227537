import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { deleteCampaign } from '../endpoints'

import Cookies from "js-cookie";

export const deleteCampaignId = createAsyncThunk('delete/deleteCampaignId', async (id) => {
    const config = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('moToken')}`
        },
        url: deleteCampaign + id,
    }
    const response = axios(config).then((res) => {
        return res
    }).catch((error) => {
        return error
    })
    return response
})

export const extraReducers = {
    [deleteCampaignId.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [deleteCampaignId.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.deleteData = payload.data
    },
    [deleteCampaignId.rejected]: (state, { error }) => {
        state.loading = false
        state.error = error
    },
}