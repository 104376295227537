import { useState, useEffect } from "react";
import style from "../../../styles/pageStyle/CampaignStyle.module.css";

function MessageInput({ setData, data, errorData, editCampaign }) {
  const [text, setText] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);

  useEffect(() => {
    if (editCampaign) {
      setText(data?.message);
    }
  }, [data?.message, editCampaign, text]);

  useEffect(() => {
    const arabicCharsPerMessage = 63;
    const englishCharsPerMessage = 153;
    const arabicFirstMessageLength = 70;
    const englishFirstMessageLength = 160;
    let remainingCharCount;

    let charsPerMessage, firstMessageLength;
    if (/[\u0600-\u06FF]/.test(text)) {
      // If text contains Arabic characters, use Arabic message length rules
      charsPerMessage = arabicCharsPerMessage;
      firstMessageLength = arabicFirstMessageLength;
    } else {
      // Otherwise, use English message length rules
      charsPerMessage = englishCharsPerMessage;
      firstMessageLength = englishFirstMessageLength;
    }

    // Calculate character count and message count based on current input
    let remainingChars = text.length;
    let remainingMessages = 1;
    if (remainingChars > firstMessageLength) {
      remainingChars -= firstMessageLength;
      remainingMessages += Math.ceil(remainingChars / charsPerMessage);
    }
    remainingCharCount =
      remainingMessages - 1 === 0
        ? firstMessageLength - text.length
        : (remainingMessages - 1) * charsPerMessage +
          firstMessageLength -
          text.length;
    // Update state with new character count and message count
    setCharCount(remainingCharCount);
    setMessageCount(remainingMessages);
  }, [text]);

  const handleTextChange = (event) => {
    setText(event.target.value);
    setData({ ...data, message: event.target.value });
  };

  return (
    <div>
      {/* <label>
        Message:
        <textarea value={text} onChange={handleTextChange} />
      </label>
      <p>Character count: {charCount}</p>
      <p>Message count: {messageCount}</p> */}

      <div className="mb-4">
        <div className="mb-2 d-flex justify-content-between">
          <label>Campaign Message</label>
          <div className={`${style.textContainer}`}>
            <p className="mb-0" style={{ fontSize: "13px" }}>
              {messageCount} Message - {charCount} Remaining Characters
            </p>
          </div>
        </div>
        <div>
          <textarea
            rows="3"
            id="message"
            onChange={handleTextChange}
            name="message"
            value={text}
            className="form-control"
          ></textarea>
          <p className="text-danger">{errorData} </p>
        </div>
      </div>
    </div>
  );
}

export default MessageInput;
