import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./getSingleCampaignAction";
const initialState = { singleCampaignData: [], loading: false, error: null }

const getAllCampaignsSlice = createSlice({
    name: 'singleCampaign',
    initialState,
    reducers: {},
    extraReducers
})

export default getAllCampaignsSlice.reducer;