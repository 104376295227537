import React from "react";

function Loading() {
  return (
    <div className={`loading`}>
      <img src="../loader.gif" alt="loading page" />
    </div>
  );
}

export default Loading;
