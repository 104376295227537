import React, { useEffect } from "react";
import Footer from "../Component/navBars/Footer";
import NavBar from "../Component/navBars/NavBar";
import RechargeNav from "../Component/navBars/RechargeNav";
import { Link, useNavigate, useParams } from "react-router-dom";
import style from "../styles/pageStyle/ChooseCreationWay.module.css";
import Cookies from "js-cookie";
import TestSideNav from "../Component/navBars/TestSideNav";
import { useDispatch } from "react-redux";

const ChooseCreationWay = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!Cookies.get("moToken")) {
      navigation("/");
    }
  }, []);
  return (
    <>
      <section className={`d-flex sectionsContainers`}>
        {/* <SideNave /> */}
        <TestSideNav />
        <div className="m-0 col-11 section-container">
          <NavBar />
          <div className="d-flex">
            <div className="bg">
              <div className="pt-5 mt-4 px-4">
                <h1 className="mt-2">Welcome TA Telecom</h1>
              </div>
              <div className="mt-5 px-4 d-flex justify-content-center mb-5">
                <div className={`text-center mx-3 ${style.createCards}`}>
                  <Link to="/campaign-section/1">
                    <div className="">
                      <div className="col-4 pb-3 m-auto">
                        <img src="../createCampaign.svg" alt="" />
                      </div>
                      <h5>Create New Campaign</h5>
                    </div>
                  </Link>
                </div>
                <div className={`text-center mx-3 ${style.createCards}`}>
                  <Link to="/list-section/1">
                    <div className="">
                      <div className="col-4 pb-3 m-auto">
                        <img src="../campaignHistory.svg" alt="" />
                      </div>
                      <h5>Create New List</h5>
                    </div>
                  </Link>
                </div>
              </div>
              <div
                className={`d-flex justify-content-center pt-5 mt-5 mb-5 pb-5 ${style.listContainer}`}
              >
                <Link to="/list-section/1">
                  <div className="d-flex">
                    <div className={`${style.createList} d-flex mx-2`}>
                      <img src="../createList.svg" alt="" />
                    </div>
                    <h4>Create New List</h4>
                  </div>
                </Link>
              </div>
            </div>
            <RechargeNav />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ChooseCreationWay;
