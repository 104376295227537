import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { login } from '../endpoints'
import Cookies from "js-cookie";

export const postLogin = createAsyncThunk('login/postLogin', async (obj) => {
    const config = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        url: login,
        data: obj
    }
    const response = axios(config).then((res) => {
        return res
    }).catch((error) => {
        console.log('error', error.response.data);

    })
    return response
})

export const extraReducers = {
    [postLogin.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [postLogin.fulfilled]: (state, { payload }) => {
        state.loading = false
        if (payload.data) {
            console.log(' payload.data', payload.data);
            state.lgoinData = payload.data
            Cookies.set('moToken', payload.data.data.token)
            Cookies.set('companyName', payload.data.data.companyName)
        } else {
            console.log('error res', payload.response.data);
            state.error = payload.response.data
        }
    },
    [postLogin.rejected]: (state, { error }) => {
        state.loading = false
        state.error = error
    },
}