import React, { useEffect, useState } from "react";
import NavBar from "../Component/navBars/NavBar";
import Footer from "../Component/navBars/Footer";
import RechargeNav from "../Component/navBars/RechargeNav";
import style from "../styles/pageStyle/Creation.module.css";
import CreateCampaign from "../Component/campaignContainers/CreateCampaign";
import CampaignHistory from "../Component/campaignContainers/CampaignHistory";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getListData } from "../state/listData/listAction";
import { getSenderData } from "../state/senderData/senderAction";
import { getAllCampaigns } from "../state/allCampaigns/getAllCampaignsAction";
import Loading from "../Component/Loading";
import ScheduledCampaigns from "../Component/campaignContainers/ScheduledCampaigns";
import TestSideNav from "../Component/navBars/TestSideNav";

const CreateCampaignContainer = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const getList = useSelector((state) => state.listData);
  const senderData = useSelector((state) => state.senderData);
  const campaignsData = useSelector((state) => state.campaignsData);
  const [active, setActive] = useState("1");
  const [history, setHistory] = useState();
  const [scheduled, setScheduled] = useState();
  const [pageCount, setPageCount] = useState(1);

  const handleSwitchComponents = (event) => {
    setActive(event.target.id);
    navigation("/campaign-section/" + event.target.id);
    // sessionStorage.setItem("activeCamp", event.target.id);
  };

  useEffect(() => {
    if (!Cookies.get("moToken")) {
      // redirect("/choose-creation-way");
      navigation("/");
    } else {
      let pageCountOrSize = 0;
      let fromHistory = true;
      dispatch(getListData(pageCountOrSize));
      dispatch(getSenderData());
      if (window.location.hash === "#/campaign-section/2") {
        dispatch(getAllCampaigns({ pageCount, fromHistory }));
      } else if (window.location.hash === "#/campaign-section/3") {
        fromHistory = false;
        dispatch(getAllCampaigns({ pageCount, fromHistory }));
      }

      setActive(params.id);
    }
  }, []);

  useEffect(() => {
    if (!Cookies.get("moToken")) {
      // redirect("/choose-creation-way");
      navigation("/");
    } else {
      let fromHistory = true;
      if (window.location.hash == "#/campaign-section/2") {
        dispatch(getAllCampaigns({ pageCount, fromHistory }));
      } else if (window.location.hash == "#/campaign-section/3") {
        fromHistory = false;
        dispatch(getAllCampaigns({ pageCount, fromHistory }));
      }
    }
  }, [pageCount, window.location.hash]);

  useEffect(() => {
    setActive(params.id ? params.id : "1");
  }, [params.id]);

  useEffect(() => {
    setHistory(campaignsData?.campaignsData?.data?.items);
    setScheduled(campaignsData?.campaignsData?.data?.items);
  }, [campaignsData]);

  useEffect(() => {}, [active]);
  return (
    <>
      <section className={`d-flex sectionsContainers`}>
        <TestSideNav />
        <div className="m-0 pages-container section-container">
          <NavBar />
          <div className="d-flex">
            {getList.loading || campaignsData.loading ? (
              <Loading />
            ) : (
              <div className="bg">
                <div className="d-flex px-5">
                  <div className={`col-2 ${style.btnContainer}`}></div>
                  <div className={` col-8 mt-4 d-flex text-center `}>
                    <button
                      id={"1"}
                      className={
                        active === "1"
                          ? `${style.active}`
                          : `${style.btnControl}`
                      }
                      onClick={handleSwitchComponents}
                    >
                      Create Campaign
                    </button>
                    <button
                      id={"2"}
                      className={
                        active === "2"
                          ? `${style.active}`
                          : `${style.btnControl}`
                      }
                      onClick={handleSwitchComponents}
                    >
                      Campaign History
                    </button>
                    <button
                      id={"3"}
                      className={
                        active === "3"
                          ? `${style.active}`
                          : `${style.btnControl}`
                      }
                      onClick={handleSwitchComponents}
                    >
                      Scheduled Campaigns
                    </button>
                  </div>
                  <div className={`col-2 ${style.btnContainer}`}></div>
                </div>
                {active === "1" ? (
                  <CreateCampaign
                    setActive={setActive}
                    listData={getList?.listData?.data?.items}
                    senderData={senderData.senderData}
                  />
                ) : active === "2" ? (
                  <CampaignHistory
                    campaignsData={history}
                    setPageCount={setPageCount}
                    pageCount={pageCount}
                    totalPageCount={
                      campaignsData?.campaignsData?.data?.totalPages
                    }
                  />
                ) : (
                  <ScheduledCampaigns
                    totalPageCount={
                      campaignsData?.campaignsData?.data?.totalPages
                    }
                    campaignsData={scheduled}
                    listData={getList?.listData?.data?.items}
                    senderData={senderData.senderData}
                    setPageCount={setPageCount}
                    pageCount={pageCount}
                  />
                )}
              </div>
            )}
            <RechargeNav />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CreateCampaignContainer;
