import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extraReducers } from "./createCampaignAction";
const initialState = { createCampaignData: [], loading: false, error: null }

const CreateCampaignSlice = createSlice({
    name: 'createCampaign',
    initialState,
    reducers: {},
    extraReducers
})

export default CreateCampaignSlice.reducer;