import React, { useEffect, useState } from "react";
import style from "../../styles/pageStyle/CampaignStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { postCreateList } from "../../state/createList/createListAction";
import ViewList from "./ViewList";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

const CreateList = ({ getList }) => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createListData = useSelector((state) => state.createListData);
  const [list, setList] = useState(true);
  const [uploadedFile, setUploadedFile] = useState();
  const [selectedListContainer, setSelectedListContainer] = useState([]);
  const [data, setData] = useState({
    CollectionName: "",
    FileDetails: "",
  });
  const [dataList, setDataList] = useState({
    CollectionName: "",
    listIds: "",
  });
  const [fileDataError, setFileDataError] = useState();
  const [listError, setListError] = useState();

  useEffect(() => {
    console.log("selected", selectedListContainer);
    setDataList({
      ...dataList,
      CollectionName: data.CollectionName,
      listIds: selectedListContainer,
    });
  }, [selectedListContainer, data]);

  useEffect(() => {
    console.log("createCapmaign", createListData);
  }, [createListData]);

  const handelChange = (e) => {
    setData({
      ...data,
      CollectionName: e.target.value,
    });
  };

  const validateList = () => {
    console.log("list", data);
    if (list && selectedListContainer.length === 0) {
      setListError("برجاء اختيار قائمة من قوائم الاتصال");
      console.log("inside not list");
      return false;
    } else if (!list && data.FileDetails == "") {
      setFileDataError("برجاء قم برفع ملف ");
      console.log("inside list");
      return false;
    }
    return true;
  };

  const handelUploadWay = () => {
    setList(!list);
  };

  const handelCreateList = (e) => {
    e.preventDefault();
    if (validateList()) {
      if (list) {
        console.log("data list ", dataList);
        dispatch(postCreateList({ dataList, list })).then((res) => {
          if (res?.payload?.data?.succeeded == true) {
            MySwal.fire({
              icon: "success",
              text: "The list has been created successfully",
            }).then(() => {
              navigate("/list-success", { replace: true });
            });
          } else if (res?.payload?.response?.data?.errors) {
            MySwal.fire({
              icon: "error",
              text: res.payload.response.data.errors.request[0],
            });
          } else if (res?.payload?.data?.succeeded === false) {
            MySwal.fire({
              icon: "error",
              text: res?.payload?.data?.message,
            });
          } else if (res?.payload?.response?.status == 500) {
            MySwal.fire({
              icon: "error",
              text: "internal server error",
            });
          }
        });
      } else {
        dispatch(postCreateList({ data, list })).then((res) => {
          if (res.payload.data.succeeded == true) {
            MySwal.fire({
              icon: "success",
              text: "The list has been created successfully",
            }).then(() => {
              navigate("/list-success", { replace: true });
            });
          } else if (res?.payload?.response?.data?.errors) {
            MySwal.fire({
              icon: "error",
              text: res.payload.response.data.errors.request[0],
            });
          } else if (res?.payload?.data?.succeeded === false) {
            MySwal.fire({
              icon: "error",
              text: res?.payload?.data?.message,
            });
          } else if (res?.payload?.response?.status == 500) {
            MySwal.fire({
              icon: "error",
              text: "internal server error",
            });
          }
        });
      }
    }
  };
  return (
    <>
      <div className="mt-5 col-7 px-5">
        <div className="mb-5 ">
          <h1>Create A New Contact's List</h1>
        </div>
        <form onSubmit={handelCreateList}>
          <div className="mb-4 col-9">
            <label className="mb-2" htmlFor="ListName">
              List Name
            </label>
            <input
              type="text"
              id="ListName"
              name="CollectionName "
              className="form-control col-9"
              required
              onChange={handelChange}
            />
          </div>
          <div className={`mb-4 dateContainer`}>
            <label className="mb-2">Select Source</label>
            <div className="d-flex col-10 justify-content-between">
              <div className="d-flex">
                <input
                  type="radio"
                  id="anotherList"
                  name="FileDetails "
                  checked={list ? true : false}
                  onChange={handelUploadWay}
                />
                <label
                  className={`px-2 ${style.radioLabels}`}
                  htmlFor="anotherList"
                >
                  Import from another list
                </label>
              </div>
              <div className="d-flex">
                <input
                  type="radio"
                  id="fromAFile"
                  name="FileDetails "
                  checked={list ? false : true}
                  onChange={handelUploadWay}
                />
                <label
                  className={`px-2 ${style.radioLabels}`}
                  htmlFor="fromAFile"
                >
                  Import from a file
                </label>
              </div>
            </div>
          </div>
          {list ? (
            <>
              <ViewList
                getList={getList}
                setSelectedListContainer={setSelectedListContainer}
                fromCreateList={true}
              />
              <p className="text-danger">{listError}</p>
            </>
          ) : (
            <div className="mb-4 col-12">
              <label className="mb-2">Upload Your File</label>
              <div className="d-flex justify-content-between">
                <input
                  type="file"
                  id="upload"
                  accept=".xlsx, .xls, .csv"
                  name="upload"
                  className="d-none"
                  onChange={(e) => {
                    setData({
                      ...data,
                      FileDetails: e.target.files[0],
                    });
                    setUploadedFile(e.target.files[0].name);
                  }}
                />
                <div className="col-7">
                  <input
                    type="text"
                    value={uploadedFile}
                    className="form-control "
                    disabled
                  />
                </div>
                <label
                  // type="button"
                  htmlFor="upload"
                  className={`btn btnBG Choose a file col-4`}
                >
                  Choose a File
                </label>
              </div>
              <p className="text-danger">{fileDataError}</p>
            </div>
          )}
          <div className="d-flex justify-content-center mt-4 mb-5">
            <button
              type="submit"
              className={`btn btnBG col-12`}
              disabled={createListData?.loading}
            >
              <div className="d-flex justify-content-center">
                {createListData?.loading && (
                  <div className="createLoader mx-2">
                    <img
                      src="/loginLoader.gif"
                      alt="login loading"
                      className={`${style.loader}`}
                    />
                  </div>
                )}
                Create List
              </div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateList;
