import React, { useEffect } from "react";
import style from "../../../styles/navStyles/Recharge.module.css";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getPackageType } from "../../../state/packageType/packageTypeAction";

const PackageType = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const packageTypeData = useSelector((state) => state.packageTypeData);

  useEffect(() => {
    if (!Cookies.get("moToken")) {
      navigation("/");
    } else {
      dispatch(getPackageType());
    }
  }, []);

  useEffect(() => {}, [packageTypeData]);
  return (
    <>
      <p className="mt-5">Package Type</p>
      <div className="col-8 text-center m-auto">
        <h5>
          {
            packageTypeData?.packageTypeData?.data?.items[0]?.pricingPlan
              ?.packageName
          }
        </h5>
        <p className={`${style.quotaDetails} m-0`}>
          SMS Quota:{" "}
          <span>
            {
              packageTypeData?.packageTypeData?.data?.items[0]?.pricingPlan
                ?.numSMSs
            }
          </span>
        </p>
        <p className={`${style.quotaDetails} m-0`}>
          In-Bundle SMS Rate:{" "}
          <span>
            {
              packageTypeData?.packageTypeData?.data?.items[0]?.pricingPlan
                ?.rate
            }
            PTs
          </span>
        </p>
      </div>
    </>
  );
};

export default PackageType;
