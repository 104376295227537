import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "../../../styles/pageStyle/CampaignStyle.module.css";
import { useDispatch } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Contact from "../contact/Contact";
import MessageInput from "../contact/Message";

const ViewCampaign = ({
  listData,
  id,
  senderData,
  editCampaign,
  singleCampaignData,
  setShow,
  setActive,
}) => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [nowDate, setNowDate] = useState();
  const [now, setNow] = useState(true);
  const [list, setList] = useState("List");
  const [contact, setContact] = useState([]);
  const [data, setData] = useState({
    campaigName: "",
    startDate: "",
    senderNameId: "",
    listId: "",
    message: "",
    numbers: contact,
    dateNow: true,
  });
  const [errorData, setErrorData] = useState({
    errorCampaignName: "",
    errorStartDate: "",
    errorSenderName: "",
    errorListId: "",
    errorNumbers: "",
    errormessage: "",
  });

  useEffect(() => {
    console.log("singleCampaignData", singleCampaignData);
    setData({
      campaigName: singleCampaignData?.data?.campaigName,
      startDate: singleCampaignData?.data?.startDate,
      senderNameId: singleCampaignData?.data?.senderNameId,
      listId: singleCampaignData?.data?.listId,
      message: singleCampaignData?.data?.message,
      numbers: singleCampaignData?.data?.listNumbers,
      id: id,
    });
    setDate(new Date(singleCampaignData?.data?.startDate));
    if (singleCampaignData?.data?.listId === 0) {
      setList(singleCampaignData?.data?.listId);
    } else {
      setList(singleCampaignData?.data?.numbers);
    }
  }, [singleCampaignData]);

  useEffect(() => {}, [list]);

  useEffect(() => {
    setData({ ...data, numbers: contact });
  }, [contact]);

  return (
    <>
      <div className={`col-12 `}>
        <form>
          <fieldset disabled="disabled">
            <div className="mb-4 col-9">
              <label className="mb-2" htmlFor="campaigName">
                Campaign Name
              </label>
              <input
                type="text"
                id="campaigName"
                name="campaigName"
                className="form-control col-9"
                value={data.campaigName}
                required
              />
            </div>
            <p className="text-danger">{errorData.errorCampaignName} </p>

            <div className={`mb-4 dateContainer`}>
              <label className="mb-2">Start Date</label>
              <div className="d-flex d-flex col-9 justify-content-between">
                <label
                  className={`px-2 ${style.radioLabels}`}
                  htmlFor="Schedule"
                >
                  Schedule
                </label>
                <Flatpickr
                  options={{
                    enableTime: true,
                    minDate: "today",
                  }}
                  value={date}
                />
              </div>
            </div>
            <div className="mb-4 col-9">
              <label className="mb-2" htmlFor="senderNameId">
                Sender Name
              </label>
              <select
                defaultValue=""
                name="senderNameId"
                className="form-control"
              >
                <option disabled value="">
                  Choose sender name
                </option>
                {senderData?.map((el, i) => (
                  <option
                    key={i}
                    selected={data?.senderNameId === el.id}
                    value={el.id}
                  >
                    {el.name}
                  </option>
                ))}
              </select>
              <p className="text-danger">{errorData.errorSenderName}</p>
            </div>
            <div className="mb-4">
              <label className="mb-2">Send to</label>
              <div className="d-flex col-8 justify-content-between">
                {list !== 0 ? (
                  <div className="d-flex">
                    <label
                      className={`px-2 ${style.radioLabels}`}
                      htmlFor="List"
                    >
                      List:
                    </label>
                  </div>
                ) : (
                  <div className="d-flex">
                    <label
                      className={`px-2 ${style.radioLabels}`}
                      htmlFor="Contacts"
                    >
                      Contacts
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4 ">
              {list !== 0 ? (
                <>
                  <div className="col-8">
                    <select
                      defaultValue=""
                      name="listId"
                      className="form-control"
                    >
                      {listData?.map((el, i) => (
                        <option
                          key={i}
                          selected={data?.listId === el.id}
                          value={+el.id}
                        >
                          {el.collectionName}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : (
                data?.numbers?.map((num, i) => (
                  <div className={`col-6 ${style.num}`} key={i}>
                    <p>{num}</p>
                  </div>
                ))
              )}
            </div>
            <p className="text-danger">
              {errorData.errorListId
                ? errorData.errorListId
                : errorData.errorNumbers}
            </p>
            <MessageInput
              setData={setData}
              data={data}
              editCampaign={editCampaign}
              errorData={errorData?.errormessage}
            />
          </fieldset>
        </form>
      </div>
    </>
  );
};

export default ViewCampaign;
