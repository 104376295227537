import React from "react";
import style from "../../styles/navStyles/Footer.module.css";

const Footer = () => {
  return (
    <footer
      className={`d-flex justify-content-center mt-1 pt-3 pb-2 ${style.footerContainer}`}
    >
      <p>Ⓒ All copyrights reserved to TA Telecom - SMS Gateway</p>
    </footer>
  );
};

export default Footer;
