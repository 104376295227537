import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { extraReducers } from "./packagesAction";

const initialState = { packages: [], loading: false, error: null }

const packagesSlice = createSlice({
    name: 'packages',
    initialState,
    reducers: {},
    extraReducers
})

export default packagesSlice.reducer;