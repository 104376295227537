import React, { useEffect, useState } from "react";
import Controller from "../Controller";
import style from "../../styles/pageStyle/CampaignStyle.module.css";
import Modal from "react-bootstrap/Modal";
import CreateCampaign from "./CreateCampaign";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCampaign } from "../../state/singleCampaign/getSingleCampaignAction";
import { deleteCampaignId } from "../../state/deleteCampaign/deleteCampaignAction";
import PaginationBar from "../PaginationBar";
import ViewCampaign from "./viewCampaign/ViewCampaign";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getAllCampaigns } from "../../state/allCampaigns/getAllCampaignsAction";

const ScheduledCampaigns = ({
  campaignsData,
  listData,
  senderData,
  setPageCount,
  pageCount,
  totalPageCount,
}) => {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const [historyData, setHistoryData] = useState();
  const [show, setShow] = useState(false);
  const [campaignId, setCampaignId] = useState();
  const singleCampaignData = useSelector((state) => state.singleCampaignData);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setCampaignId(id);
    dispatch(getSingleCampaign(id));
    setShow(true);
  };

  useEffect(() => {
    setHistoryData(campaignsData);
  }, [campaignsData]);

  const handelDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const dateValue = new Date(date).toLocaleDateString("en-EN", options);
    const timeValue = new Date(date);
    const getHours = timeValue.getHours();
    const getMins = timeValue.getMinutes();
    return (
      <>
        {dateValue} {getHours}:{getMins}
      </>
    );
  };

  const handelDelete = (id) => {
    dispatch(deleteCampaignId(id)).then((res) => {
      MySwal.fire({
        icon: "success",
        text: res?.payload?.data,
      })
        .then(() => {
          let fromHistory = false;
          dispatch(getAllCampaigns({ pageCount, fromHistory }));
        })
        .catch((error) => {
          MySwal.fire({
            icon: "error",
            text: res.payload.response.data.errors.request[0],
          });
        });
    });
  };

  return (
    <div>
      <>
        <div className="mt-5 px-5">
          <div className="mb-5">
            <h1>Scheduled Campaigns</h1>
          </div>
          <Controller
            history={true}
            historyData={historyData}
            setHistoryData={setHistoryData}
            campaignsData={campaignsData}
            pageCount={pageCount}
          />
          <table className="table table-striped table-light table-hover">
            <thead className="table-light">
              <tr className="table-secondary">
                <th scope="col">Campaign name</th>
                <th scope="col">Date/Time</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {historyData?.map((data, i) => (
                <>
                  <tr key={i}>
                    <td>{data.campaigName}</td>
                    <td>{handelDate(data.startDate)}</td>
                    <td className={`${style.editDelete} `}>
                      <div
                        className="d-inline-block pointer"
                        onClick={() => handleShow(data.id)}
                      >
                        <div className="d-flex">
                          View
                          <div className="mx-1">
                            <img src="../feather.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className={`${style.editDelete} `}>
                      <div
                        className="d-inline-block pointer"
                        onClick={() => {
                          handelDelete(data.id);
                        }}
                      >
                        <div className="d-flex">
                          Delete
                          <div className="mx-1">
                            <img src="../delete.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
        <Modal className="edit-modal" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>View Campaign</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewCampaign
              editCampaign={true}
              listData={listData}
              senderData={senderData}
              id={campaignId}
              setShow={setShow}
              singleCampaignData={singleCampaignData.singleCampaignData}
            />
            {/* <CreateCampaign
              editCampaign={true}
              listData={listData}
              senderData={senderData}
              id={campaignId}
              setShow={setShow}
              singleCampaignData={singleCampaignData.singleCampaignData}
            /> */}
          </Modal.Body>
        </Modal>
        <div className="d-flex justify-content-center">
          <PaginationBar
            historyData={historyData}
            setHistoryData={setHistoryData}
            setPageCount={setPageCount}
            pageCount={pageCount}
            totalPageCount={totalPageCount}
          />
        </div>
      </>
    </div>
  );
};

export default ScheduledCampaigns;
