import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extraReducers } from "./loginAction";
const initialState = { loginData: [], loading: false, error: null }

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers
})

export default loginSlice.reducer;