import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createNewList, createNewListWithList } from '../endpoints'
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export const postCreateList = createAsyncThunk('createList/postCreateList', async (obj) => {
    const formData = new FormData();
    if (!obj.list) {
        formData.set('CollectionName', obj.data.CollectionName)
        formData.set('FileDetails', obj.data.FileDetails)
    }
    const config = {
        method: 'post',
        headers: {
            'Content-Type': obj.list ? 'application/json' : 'multipart/form-data',
            'Authorization': `Bearer ${Cookies.get('moToken')}`
        },
        url: obj.list ? createNewListWithList : createNewList,
        data: obj.list ? obj.dataList : formData
    }
    console.log('res', formData);
    const response = axios(config).then((res) => {
        return res
    }).catch((error) => {
        console.log('error', error.response.data);
        MySwal.fire({
            icon: "error",
            text: error.response.data
        });
    })
    return response
})

export const extraReducers = {
    [postCreateList.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [postCreateList.fulfilled]: (state, action) => {
        state.loading = false
        console.log('payload,', action);
        // state.createListData = payload.data
        // MySwal.fire({
        //     icon: "success",
        //     text: payload.data.message
        // });
    },
    [postCreateList.rejected]: (state, { error }) => {
        state.loading = false
        state.error = error
    },
}