import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./packageTypeAction";
const initialState = { packageTypeData: [], loading: false, error: null }

const packageTypeSlice = createSlice({
    name: 'packageType',
    initialState,
    reducers: {},
    extraReducers
})

export default packageTypeSlice.reducer;