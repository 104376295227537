import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "../../styles/pageStyle/CampaignStyle.module.css";
import { postCreateCampaign } from "../../state/createCampagin/createCampaignAction";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { postEditCampaign } from "../../state/editCampaign/editAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getAllCampaigns } from "../../state/allCampaigns/getAllCampaignsAction";
import Contact from "./contact/Contact";
import MessageInput from "./contact/Message";

const CreateCampaign = ({
  listData,
  id,
  senderData,
  editCampaign,
  singleCampaignData,
  setShow,
  setActive,
}) => {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createCampaignData = useSelector((state) => state.createCampaignData);
  const [date, setDate] = useState(new Date());
  const [nowDate, setNowDate] = useState();
  const [now, setNow] = useState(true);
  const [list, setList] = useState("List");
  const [contact, setContact] = useState([]);
  const [data, setData] = useState({
    campaigName: "",
    startDate: "",
    senderNameId: "",
    listId: 0,
    message: "",
    numbers: contact,
    dateNow: true,
  });
  const [errorData, setErrorData] = useState({
    errorCampaignName: "",
    errorStartDate: "",
    errorSenderName: "",
    errorListId: "",
    errorNumbers: "",
    errormessage: "",
  });

  useEffect(() => {
    if (!editCampaign) {
      let cc = new Date();
      cc.setHours(cc.getHours() + 3);
      setData({ ...data, startDate: cc.toISOString() });
    }
  }, []);

  useEffect(() => {}, [createCampaignData]);

  useEffect(() => {
    if (editCampaign) {
      setData({
        campaigName: singleCampaignData?.data?.campaigName,
        startDate: singleCampaignData?.data?.startDate,
        senderNameId: singleCampaignData?.data?.senderNameId,
        listId: singleCampaignData?.data?.listId,
        message: singleCampaignData?.data?.message,
        numbers: null,
        id: id,
      });
      setDate(new Date(singleCampaignData?.data?.startDate));
      if (singleCampaignData?.data?.listId === 0) {
        setList("List");
      } else {
        setList("Contacts");
      }
    }
  }, [singleCampaignData]);

  useEffect(() => {}, [list]);

  useEffect(() => {
    setData({ ...data, numbers: contact });
  }, [contact]);

  const handelChange = (e) => {
    if (now) {
      let cc = new Date();
      cc.setHours(cc.getHours() + 3);
      setNowDate(cc.toISOString());
    }
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handelListChange = (e) => {
    setList(e.target.value);
    if (e.target.value === "Contacts") {
      setData({ ...data, listId: 0 });
    } else {
      setData({ ...data, numbers: null });
    }
    setErrorData({
      ...errorData,
      errorListId: "",
      errorNumbers: "",
    });
  };

  const handelNow = (e) => {
    setNow(!now);
    if (!now) {
      let cc = new Date();
      cc.setHours(cc.getHours() + 3);
      setData({ ...data, startDate: cc.toISOString() });
    }
  };

  const handelDateTime = (dataTime) => {
    let cc = new Date(...dataTime);
    cc.setHours(cc.getHours() + 3);
    setData({ ...data, startDate: cc.toISOString(), dateNow: false });
  };

  const validate = () => {
    if (data.campaigName === "" || data.campaigName.length < 3) {
      setErrorData({
        ...errorData,
        errorCampaignName: "name should contain 3 characters at least",
      });
      return false;
    }
    if (data.senderNameId === "" || data.senderNameId === null) {
      setErrorData({
        ...errorData,
        errorSenderName: "please choose sender to",
      });
      return false;
    }
    if (list == "List" && data.listId === 0) {
      setErrorData({
        ...errorData,
        errorListId: "please choose send to ",
      });
      return false;
    }
    if ((list == "Contacts" && contact.length === 0) || contact === null) {
      setErrorData({
        ...errorData,
        errorNumbers: "please enter the numbers you want to contact",
      });
      return false;
    }
    if (data.message === "" || data.message < 10) {
      setErrorData({
        ...errorData,
        errormessage: "The message must have at least 10 characters",
      });
      return;
    }
    return true;
  };

  const handelCreateCampaign = (e) => {
    e.preventDefault();
    if (validate()) {
      if (editCampaign) {
        dispatch(postEditCampaign(data)).then((res) => {
          if (res.payload.data.data == false) {
            MySwal.fire({
              icon: "error",
              text: res.payload.data.message,
            });
          } else if (res.payload.response.status == 500) {
            MySwal.fire({
              icon: "error",
              text: "internal server error",
            });
          } else {
            MySwal.fire({
              icon: "success",
              text: res.payload.data.message,
            }).then(() => {
              setShow(false);
              dispatch(getAllCampaigns());
            });
          }
        });
      } else {
        let newData = { ...data };
        if (now) {
          let addingMinutes = new Date(nowDate);
          addingMinutes.setMinutes(addingMinutes.getMinutes() + 3);
          newData.startDate = null;
          newData.dateNow = true;
        }
        dispatch(postCreateCampaign(newData)).then((res) => {
          if (res.payload.data.succeeded == true) {
            MySwal.fire({
              icon: "success",
              text: res?.payload?.data?.message,
            }).then(() => {
              now
                ? navigate("/campaign-success/2", { replace: true })
                : navigate("/campaign-success/3", { replace: true });
              setErrorData({
                errorCampaignName: "",
                errorStartDate: "",
                errorSenderName: "",
                errorListId: "",
                errorNumbers: "",
              });
            });
          } else if (res?.payload?.response?.data?.errors) {
            MySwal.fire({
              icon: "error",
              text: res.payload.response.data.errors.request[0],
            });
          } else if (res?.payload?.data?.succeeded === false) {
            MySwal.fire({
              icon: "error",
              text: res?.payload?.data?.message,
            });
          } else if (res.payload.response.status == 500) {
            MySwal.fire({
              icon: "error",
              text: "internal server error",
            });
          }
        });
      }
    }
  };
  return (
    <>
      <div className={`${!editCampaign ? "mt-5 col-6 px-5" : "col-12"} `}>
        {editCampaign ? null : (
          <div className="mb-5">
            <h1>Create New Campaign</h1>
          </div>
        )}
        <form onSubmit={handelCreateCampaign}>
          <div className="mb-4 col-9">
            <label className="mb-2" htmlFor="campaigName">
              Campaign Name
            </label>
            <input
              type="text"
              id="campaigName"
              name="campaigName"
              className="form-control col-9"
              value={data.campaigName}
              required
              onChange={handelChange}
            />
          </div>
          <p className="text-danger">{errorData.errorCampaignName} </p>

          <div className={`mb-4 dateContainer`}>
            <label className="mb-2">Start Date</label>
            <div className="d-flex d-flex col-9 justify-content-between">
              {editCampaign ? (
                <>
                  <label
                    className={`px-2 ${style.radioLabels}`}
                    htmlFor="Schedule"
                  >
                    Schedule
                  </label>
                  <Flatpickr
                    options={{
                      enableTime: true,
                      minDate: "today",
                    }}
                    value={date}
                    onChange={(data) => {
                      handelDateTime(data);
                    }}
                  />
                </>
              ) : (
                <>
                  <div className="d-flex">
                    <input
                      type="radio"
                      id="startDate"
                      name="startDate"
                      value={date}
                      checked={now ? true : false}
                      onChange={handelNow}
                    />
                    <label
                      className={`px-2 ${style.radioLabels}`}
                      htmlFor="startDate"
                    >
                      Now
                    </label>
                  </div>
                  <div className="d-flex">
                    <input
                      type="radio"
                      id="Schedule"
                      name="startDate"
                      checked={now ? false : true}
                      value={date}
                      onChange={() => {
                        handelNow();
                        setDate(new Date());
                      }}
                    />
                    <label
                      className={`px-2 ${style.radioLabels}`}
                      htmlFor="Schedule"
                    >
                      Schedule
                    </label>
                  </div>
                  <Flatpickr
                    options={{
                      enableTime: true,
                      minDate: "today",
                    }}
                    disabled={now ? true : false}
                    placeholder="اختر اليوم والساعه"
                    value={date}
                    onChange={(data) => {
                      handelDateTime(data);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="mb-4 col-9">
            <label className="mb-2" htmlFor="senderNameId">
              Sender Name
            </label>
            <select
              defaultValue=""
              onChange={handelChange}
              name="senderNameId"
              className="form-control"
            >
              <option disabled value="">
                Choose sender name
              </option>
              {senderData?.map((el, i) => (
                <option
                  key={i}
                  selected={editCampaign ? data?.senderNameId === el.id : false}
                  value={el.id}
                >
                  {el.name}
                </option>
              ))}
            </select>
            <p className="text-danger">{errorData.errorSenderName}</p>
          </div>
          <div className="mb-4">
            <label className="mb-2">Send to</label>
            <div className="d-flex col-8 justify-content-between">
              <div className="d-flex">
                <input
                  type="radio"
                  id="List"
                  name="chooseList"
                  value={"List"}
                  //   className="form-control"
                  checked={list === "List"}
                  onChange={handelListChange}
                />
                <label className={`px-2 ${style.radioLabels}`} htmlFor="List">
                  List
                </label>
              </div>
              <div className="d-flex">
                <input
                  type="radio"
                  id="Contacts"
                  name="chooseList"
                  value={"Contacts"}
                  //   className="form-control"
                  onChange={handelListChange}
                />
                <label
                  className={`px-2 ${style.radioLabels}`}
                  htmlFor="Contacts"
                >
                  Contacts
                </label>
              </div>
            </div>
          </div>
          <div className="mb-4 ">
            <div className="d-flex">
              {list === "List" ? (
                <>
                  <div className="col-8">
                    <select
                      defaultValue=""
                      onChange={handelChange}
                      name="listId"
                      className="form-control"
                    >
                      <option disabled value={""}>
                        Choose from your list
                      </option>
                      {listData?.map((el, i) => (
                        <option
                          key={i}
                          selected={
                            editCampaign ? data?.listId === el.id : false
                          }
                          value={+el.id}
                        >
                          {el.collectionName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={`${style.textContainer}`}>
                    <Link
                      to={"/list-section/1"}
                      className={`${style.createList} ${style.textColor}`}
                    >
                      + Create a new list
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <Contact setContact={setContact} contact={contact} />
                </>
              )}
            </div>
            <p className="text-danger">
              {errorData.errorListId
                ? errorData.errorListId
                : errorData.errorNumbers}
            </p>
          </div>
          <MessageInput
            setData={setData}
            data={data}
            editCampaign={editCampaign}
            errorData={errorData?.errormessage}
          />
          <div className="d-flex justify-content-center mt-4 mb-5">
            <button
              type="submit"
              className={`btn btnBG col-12`}
              disabled={createCampaignData?.loading}
            >
              <div className="d-flex justify-content-center">
                {createCampaignData?.loading && (
                  <div className="createLoader mx-2">
                    <img
                      src="/loginLoader.gif"
                      alt="login loading"
                      className={`${style.loader}`}
                    />
                  </div>
                )}
                {editCampaign ? "Edit Campaign" : "Create Campaign"}
              </div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateCampaign;
