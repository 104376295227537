import React, { useEffect } from "react";
import NavBar from "../Component/navBars/NavBar";
import Footer from "../Component/navBars/Footer";
import RechargeNav from "../Component/navBars/RechargeNav";
import style from "../styles/pageStyle/Success.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { getAllCampaigns } from "../state/allCampaigns/getAllCampaignsAction";
import { useDispatch } from "react-redux";

const CampaignSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  return (
    <>
      <NavBar />
      <section className={`d-flex justify-content-between`}>
        <div className="bg col-9 container">
          <div className="d-flex algin-content-center mt-5 pt-5 col-7 m-auto">
            <div className="text-center">
              <div className={`${style.successIcon}  m-auto mb-4`}>
                <img src="../createCampaign.svg" alt="" />
              </div>
              <div className={`${style.contentStyle}`}>
                <h1>Campaign Created Successfully</h1>
                <p>
                  Your campaign has been created successfully. You can now check
                  your new campaign history
                </p>
              </div>
              <button
                type="button"
                className="btn btnBG mt-3 col-7"
                onClick={() => {
                  dispatch(getAllCampaigns()).then(() => {
                    params.id == 2
                      ? navigate("/campaign-section/2")
                      : navigate("/campaign-section/3");
                  });
                }}
              >
                View Campaigns
              </button>
            </div>
          </div>
        </div>
        <RechargeNav />
      </section>
      <Footer />
    </>
  );
};

export default CampaignSuccess;
