import React from "react";
import style from "../../styles/navStyles/NavBAr.module.css";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const NavBar = () => {
  const navigate = useNavigate();

  return (
    <section
      className={`d-flex px-2 justify-content-between ${style.navContainer}`}
    >
      <div className={`${style.logoContainer} col-2`}>
        <Link to={"/choose-creation-way"}>
          <img src="../../mobise-logo.png" alt="" />
        </Link>
      </div>
      <div className={`col-5 d-flex ${style.contentContainer}`}>
        <div
          className="dropdown-toggle m-0 d-flex justify-content-end align-items-center"
          d="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className={`${style.userContentCreator}`}>
            <p className="mb-0">Welcome</p>
            <h5>{Cookies.get("companyName")}</h5>
          </div>
          <div className={`${style.userIMGCreator} mx-2`}></div>
          <img src="../userLogo.png" alt="company logo" />
        </div>
        <div
          className="dropdown-menu px-1 text-right"
          aria-labelledby="dropdownMenuButton1"
        >
          <p
            className={`pointer mb-0 ${style.logout}`}
            onClick={() => {
              Cookies.remove("moToken");
              Cookies.remove("companyName");
              navigate("/login");
            }}
          >
            Logout
          </p>
        </div>
      </div>
    </section>
  );
};

export default NavBar;
