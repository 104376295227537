import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./getAllCampaignsAction";
const initialState = { campaignsData: [], loading: false, error: null }

const getAllCampaignsSlice = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {},
    extraReducers
})

export default getAllCampaignsSlice.reducer;