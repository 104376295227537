import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extraReducers } from "./createListAction";
const initialState = { createListData: [], loading: false, error: null }

const CreateListSlice = createSlice({
    name: 'createList',
    initialState,
    reducers: {},
    extraReducers
})

export default CreateListSlice.reducer;