import React, { useState, useEffect } from "react";
import style from "../styles/pageStyle/Login.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { postLogin } from "../state/login/loginAction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Cookies from "js-cookie";
import secureLocalStorage from "react-secure-storage";

const Login = () => {
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const navigation = useNavigate();
  const loginSlice = useSelector((state) => state.loginData);
  const { t } = useTranslation(["translation"]);
  const [data, setData] = useState({
    email: "",
    password: "",
    // checkBox: false,
  });
  const [checked, setChecked] = useState();

  const handelChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (Cookies.get("moToken")) {
      navigation("/choose-creation-way");
    }
    setChecked(
      localStorage.getItem("checkedMark")
        ? localStorage.getItem("checkedMark")
        : false
    );
    setData({
      email: secureLocalStorage.getItem("mobMail")
        ? secureLocalStorage.getItem("mobMail")
        : "",
      password: secureLocalStorage.getItem("mobPass")
        ? secureLocalStorage.getItem("mobPass")
        : "",
    });
  }, []);

  useEffect(() => {}, [
    checked,
    localStorage.getItem("checkedMark"),
    secureLocalStorage.getItem("mobMail"),
    secureLocalStorage.getItem("mobPass"),
  ]);

  const validateEmail = () => {
    if (data.email) {
      return true;
    }
    return false;
  };

  const validatePass = () => {
    if (data.password && data.password.length >= 5) {
      return true;
    }
    return false;
  };

  const handelLogin = (e) => {
    e.preventDefault();
    if (validateEmail() && validatePass()) {
      dispatch(postLogin(data)).then(() => {
        navigation("/choose-creation-way");
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "عذراً",
        text: "برجاء التاكد من صحة البيانات اولا",
      });
    }
  };
  return (
    <>
      <section className={`login-bg`}>
        <div className=" container">
          <div className={`row `}>
            <div className={`col-md-6 col-12  d-flex ${style.centerContent}`}>
              <div className={`${style.imgContainer}  align`}>
                <img src="/mobise-logo.png" alt="" />
              </div>
            </div>
            <div className={`col-md-6 col-12 ${style.verticalLine}`}>
              <div className={`${style.formContainer}`}>
                <div className={`${style.title} mb-5`}>
                  <h1>{t("translation:title")}</h1>
                </div>
                <form onSubmit={handelLogin}>
                  <div className="mb-4">
                    <label className="mb-2" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={data.email}
                      name="email"
                      className="form-control"
                      required
                      onChange={handelChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="mb-2" htmlFor="password">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      value={data.password}
                      name="password"
                      className="form-control"
                      required
                      onChange={handelChange}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        checked={checked}
                        onChange={() => {
                          setChecked(!checked);
                          if (checked) {
                            localStorage.removeItem("checkedMark");
                            secureLocalStorage.removeItem("mobMail");
                            secureLocalStorage.removeItem("mobPass");
                          } else {
                            localStorage.setItem("checkedMark", true);
                            secureLocalStorage.setItem("mobMail", data.email);
                            secureLocalStorage.setItem(
                              "mobPass",
                              data.password
                            );
                          }
                        }}
                      />
                      <label className="mx-3" htmlFor="checkbox">
                        Remember me
                      </label>
                    </div>
                    <div>
                      <Link to="/">Forgot Password ?</Link>
                    </div>
                  </div>
                  <div className="justify-content-center mt-5">
                    {loginSlice.error && (
                      <p className="text-danger">{loginSlice.error}</p>
                    )}
                    <button
                      type="submit"
                      disabled={loginSlice.loading}
                      className={`btn btnBG col-12`}
                    >
                      {loginSlice.loading && (
                        <img
                          src="/loginLoader.gif"
                          alt="login loading"
                          className={`${style.loader} mx-2`}
                        />
                      )}
                      login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="d-flex justify-content-center pt-4 pb-3">
        <p>Ⓒ All copyrights reserved to TA Telecom - SMS Gateway</p>
      </footer>
    </>
  );
};

export default Login;
