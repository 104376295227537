import React from "react";
import { useState } from "react";
import style from "../../../styles/pageStyle/CampaignStyle.module.css";

const Contact = ({ setContact, contact }) => {
  const [num, setNum] = useState("");
  const [numErr, setNumErr] = useState("");

  const handelChangeNum = (e) => {
    setNum(e.target.value.replace(/[^0-9]/g, ""));
  };

  const handelDeleteNum = (i) => {
    const deleteVal = [...contact];
    deleteVal.splice(i, 1);
    setContact(deleteVal);
  };

  const handelSubmitNum = () => {
    if (num.startsWith("01") && num.length === 11) {
      setContact([...contact, num]);
      setNum("");
      setNumErr("");
    } else if (num.startsWith("201") && num.length === 12) {
      setContact([...contact, num]);
      setNum("");
      setNumErr("");
    } else {
      setNumErr("please enter valid number");
    }
  };

  return (
    <div className="col-12">
      <div className="d-flex col-10">
        <input
          type="text"
          className="form-control"
          value={num}
          placeholder="201********"
          onChange={handelChangeNum}
        />
        <div className={`${style.textContainer}`}>
          <p
            className={`pointer align-self-center mx-2 mb-0 ${style.add} ${style.textColor}`}
            onClick={handelSubmitNum}
          >
            Add
          </p>
        </div>
      </div>
      <p className="text-danger">{numErr}</p>
      {contact &&
        contact?.map((num, i) => (
          <div
            className={`d-flex justify-content-between mt-3 col-12 ${style.num}`}
            key={i}
          >
            <p>{num}</p>
            <span className="pointer" onClick={() => handelDeleteNum(i)}>
              x
            </span>
          </div>
        ))}
    </div>
  );
};

export default Contact;
