import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";

const PaginationBar = ({
  historyData,
  setPageCount,
  pageCount,
  totalPageCount,
}) => {
  useEffect(() => {}, [historyData]);
  return (
    <Stack spacing={2}>
      <Pagination
        count={totalPageCount}
        color="primary"
        page={pageCount}
        onChange={(e, value) => setPageCount(value)}
      />
    </Stack>
  );
};

export default PaginationBar;
