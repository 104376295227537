import React, { useEffect } from "react";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import style from "../../styles/navStyles/Recharge.module.css";
import PackageType from "./packageType/PackageType";
import { useNavigate } from "react-router-dom";
import { getQuota } from "../../state/quota/getQuotaAction";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

const RechargeNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quotaData = useSelector((state) => state.quotaData);

  useEffect(() => {
    if (!Cookies.get("moToken")) {
      navigate("/");
    } else {
      dispatch(getQuota());
    }
  }, []);

  useEffect(() => {}, [quotaData]);

  return (
    <>
      <section className={`container mt-4 ${style.rechargeContainer}`}>
        <p>Quota Usage:</p>
        <div className="col-7 m-auto pb-5">
          <CircularProgressbarWithChildren
            value={
              100 -
              (quotaData?.quotaData?.data?.usedQuota /
                quotaData?.quotaData?.data?.remaining) *
                100
            }
          >
            <h5>
              {quotaData?.quotaData?.data?.remaining -
                quotaData?.quotaData?.data?.usedQuota}{" "}
              <span>SMS</span>
            </h5>
            <span>Remaining Of</span>
            <p>{quotaData?.quotaData?.data?.remaining}</p>
          </CircularProgressbarWithChildren>
          <button
            type="button"
            className="btn btnBG mt-5 col-12"
            onClick={() => {
              navigate("/packages");
            }}
          >
            Recharge
          </button>
        </div>
        <PackageType />
      </section>
    </>
  );
};

export default RechargeNav;
