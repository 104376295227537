import React, { useEffect, useState } from "react";
import style from "../../styles/pageStyle/CampaignStyle.module.css";
import Controller from "../Controller";
import PaginationBar from "../PaginationBar";

const CampaignHistory = ({
  campaignsData,
  setPageCount,
  pageCount,
  totalPageCount,
}) => {
  const [historyData, setHistoryData] = useState();

  useEffect(() => {
    setHistoryData(campaignsData);
  }, [campaignsData]);

  const handelDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const dateValue = new Date(date).toLocaleDateString("en-EN", options);
    const timeValue = new Date(date);
    const getHours = timeValue.getHours();
    const getMins = timeValue.getMinutes();
    return (
      <>
        {dateValue} {getHours}:{getMins}
      </>
    );
  };

  return (
    <>
      <div className="mt-5 px-5">
        <div className="mb-5">
          <h1>Campaigns History</h1>
        </div>
        <Controller
          history={true}
          historyData={historyData}
          setHistoryData={setHistoryData}
          campaignsData={campaignsData}
          pageCount={pageCount}
        />
        <table className="table table-striped table-light table-hover">
          <thead className="table-light">
            <tr className="table-secondary">
              <th scope="col">Campaign Name</th>
              <th scope="col">Date/Time</th>
              <th scope="col">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {historyData?.map((data, i) => (
              <>
                <tr
                  key={i}
                  className="pointer"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${i}`}
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <td>{data.campaigName}</td>
                  <td>{handelDate(data.startDate)}</td>
                  <td>
                    {data.status == 0
                      ? "Failed"
                      : data.status == 1
                      ? "Finished"
                      : "Active"}
                  </td>
                  <td>
                    {data.status == 0 ? (
                      <div className={`${style.failed} ${style.circle}`}></div>
                    ) : data.status == 1 ? (
                      <div
                        className={`${style.finished} ${style.circle}`}
                      ></div>
                    ) : (
                      <div className={`${style.active} ${style.circle}`}></div>
                    )}
                  </td>
                </tr>
                <tr
                  id={`collapse${i}`}
                  className="accordion-collapse collapse table-secondary"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <td>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Campaign Name: <span>{data.campaigName}</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Frequency: <span>Once</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Creation Date:{" "}
                        <span>{handelDate(data.creationDate)}</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Start Date: <span>{handelDate(data.startDate)}</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Sender Name: <span>{data.senderName}</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Total Characters: <span>{data.totalCharacters}</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Recipient Count: <span>{data.recipientCount}</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Total SMS: <span>{data.totalMessages}</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Campaign Type: <span>Normal</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Delivered: <span>{data.delivered}</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Pending: <span>{data.pending}</span>
                      </p>
                    </div>
                    <div>
                      <p className={`mb-0 ${style.details}`}>
                        Failed: <span>{data.failed}</span>
                      </p>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-center">
          <PaginationBar
            historyData={historyData}
            setHistoryData={setHistoryData}
            setPageCount={setPageCount}
            pageCount={pageCount}
            totalPageCount={totalPageCount}
          />
        </div>
      </div>
    </>
  );
};

export default CampaignHistory;
