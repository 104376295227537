import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { deleteCampaign, deleteLists } from '../endpoints'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Cookies from "js-cookie";

const MySwal = withReactContent(Swal);
export const deleteListsIds = createAsyncThunk('deleteLists/deleteListsIds', async (ids) => {
    const config = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('moToken')}`
        },
        url: deleteLists,
        data: ids
    }
    const response = axios(config).then((res) => {
        return res
    }).catch((error) => {
        return error
    })
    return response
})

export const extraReducers = {
    [deleteListsIds.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [deleteListsIds.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.deleteListData = payload.data
        MySwal.fire({
            icon: "success",
            text: payload.data.message
        });
    },
    [deleteListsIds.rejected]: (state, { error }) => {
        state.loading = false
        state.error = error
    },
}