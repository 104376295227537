import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { listData, listDataSize } from '../endpoints'
import Cookies from "js-cookie";

export const getListData = createAsyncThunk('lists/getListData', async (pageCount) => {
    const config = {
        method: 'get',
        url: pageCount === 0 ? listDataSize : listData + pageCount,
        headers: {
            'Authorization': `Bearer ${Cookies.get('moToken')}`
        }
    }
    try {
        const response = axios(config).then((res) => {
            return res
        })
        return response
    } catch (error) {
        return error
    }
})

export const extraReducers = {
    [getListData.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [getListData.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.listData = payload.data
    },
    [getListData.rejected]: (state, { error }) => {
        state.loading = false
        state.error = error
    },
}