import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { createCamp } from '../endpoints'
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export const postCreateCampaign = createAsyncThunk('createCampaign/postCreateCampaign', async (obj) => {
    obj.numbers = obj.numbers.length == 0 ? null : obj.numbers
    obj.listId = obj.listId !== 0 ? Number(obj.listId) : 0
    obj.senderNameId = Number(obj.senderNameId)
    const config = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('moToken')}`
        },
        url: createCamp,
        data: obj
    }
    const response = axios(config).then((res) => {
        return res
    }).catch((error) => {
        console.log('error', error.response.data);
    })
    return response
})

export const extraReducers = {
    [postCreateCampaign.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [postCreateCampaign.fulfilled]: (state, { payload }) => {
        state.loading = false
        // if (payload.data) {
        state.createCampaignData = payload.data

        // } else {
        //     console.log('error res', payload.response.data);
        //     state.error = payload.response.data
        // }
    },
    [postCreateCampaign.rejected]: (state, { error }) => {
        state.loading = false
        state.error = error
    },
}