// const base = 'https://172.10.10.30/BulkSms/api/'
// const base = 'https://62.68.253.187//BulkSms/api/'
const base = 'https://mobise.tatelecom.com/mobiseBEAPIs/api/'

export const login = base + 'Account/login'

export const getAllPackages = base + 'PricingPlan/GetAll'

// create campaign api 
export const createCamp = base + 'Campaigns/AddCampaign'
export const getSender = base + 'Senders/GetAll'

// campaign history 
export const allHistoryCampaigns = base + 'Campaigns/GetAllHisotry?PageNumber='

// scheduled campaigns
export const allScheduledCampaigns = base + 'Campaigns/GetAllScheduled?PageNumber='
export const singleCampaign = base + 'Campaigns/GetCampaignById?id='
export const editCampaign = base + 'Campaigns/EditCampaign'
export const deleteCampaign = base + 'Campaigns/DeleteId?id='

// view list api
export const listData = base + 'CollectionNumbers/GetAll?PageNumber='
export const listDataSize = base + 'CollectionNumbers/GetAll?PageSize=100'

// create list 
export const createNewList = base + 'PhoneNumbers/UploadExcelNumbers'
export const createNewListWithList = base + 'PhoneNumbers/MultiCollectionNumbers'

// delete lists
export const deleteLists = base + 'PhoneNumbers/DeleteList'


// package type 
export const getPackageTypeSubscription = base + 'Subscription/GetAll'

// get quota 
export const getQuotaApi = base + 'Account'

