import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./listAction";
const initialState = { listData: [], loading: false, error: null }

const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {},
    extraReducers
})

export default listSlice.reducer;