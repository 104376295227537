import React, { useEffect, useMemo, useState } from "react";
import Footer from "../Component/navBars/Footer";
import NavBar from "../Component/navBars/NavBar";
import RechargeNav from "../Component/navBars/RechargeNav";
import style from "../styles/pageStyle/Packages.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../state/packages/packagesAction";
import Loading from "../Component/Loading";
import TestSideNav from "../Component/navBars/TestSideNav";

const Packages = () => {
  const dispatch = useDispatch();
  const getDataPackages = useSelector((state) => state.packages);
  const [activeTitle, setActiveTitle] = useState();

  useEffect(() => {
    dispatch(getPackages());
  }, []);
  useEffect(() => {
    let filterActiveTitle = getDataPackages?.packages?.data?.items?.filter(
      (active) => active.active == true
    );
    setActiveTitle(filterActiveTitle && filterActiveTitle[0]);
  }, [getDataPackages]);

  useMemo(() => getDataPackages, []);

  return (
    <>
      <section className="d-flex sectionsContainers">
        <TestSideNav />
        <div className="m-0 pages-container">
          <NavBar />
          <div className="d-flex">
            {getDataPackages.loading ? (
              <Loading />
            ) : (
              <div className="bg">
                <div className=" px-5">
                  <div className="pt-5 mt-4 mb-4">
                    <h1 className="mt-2 mb-4">Welcome TA Telecom</h1>
                    {console.log(activeTitle)}
                    <h5>
                      Current Plan:{" "}
                      <span className={`${style.currentPackage}`}>
                        {activeTitle?.packageName}
                      </span>
                    </h5>
                  </div>
                  <div className="row mt-1">
                    {getDataPackages?.packages?.data?.items.map((x, i) => (
                      <div className="p-2 col-4 " key={i}>
                        <div
                          className={`${
                            x.active ? style.activeContainer : style.container
                          } px-2 py-4 text-center`}
                        >
                          <div className={`${style.main}`}>
                            <h4>{x.packageName}</h4>
                            <p>{x.totalCost} EGP</p>
                          </div>
                          <hr />
                          <div className={`${style.content}`}>
                            <p className="m-0">
                              SMS Quota:{" "}
                              <span className={`${style.price}`}>
                                {x.numSMSs}
                              </span>
                            </p>
                            <p className="m-0">
                              In-Bundle SMS Rate:{" "}
                              <span className={`${style.price}`}>{x.rate}</span>
                            </p>
                          </div>
                          <div className="d-flex justify-content-center mt-3">
                            <button type="submit" className={`btn btnBG col-6`}>
                              Recharge
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <RechargeNav />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Packages;
