import React, { useEffect, useMemo, useState } from "react";
import NavBar from "../Component/navBars/NavBar";
import Footer from "../Component/navBars/Footer";
import RechargeNav from "../Component/navBars/RechargeNav";
import style from "../styles/pageStyle/Creation.module.css";
import CreateCampaign from "../Component/campaignContainers/CreateCampaign";
import CreateList from "../Component/listContainer/CreateList";
import ViewList from "../Component/listContainer/ViewList";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Component/Loading";
import { getListData } from "../state/listData/listAction";
import { useDispatch, useSelector } from "react-redux";
import TestSideNav from "../Component/navBars/TestSideNav";
import TestViewList from "../Component/listContainer/TestViewList";

const CreateListContainer = () => {
  const navigation = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [active, setActive] = useState("1");
  const getList = useSelector((state) => state.listData);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (!Cookies.get("moToken")) {
      // redirect("/choose-creation-way");
      navigation("/");
    } else {
      dispatch(getListData(pageCount));
    }
  }, []);

  useEffect(() => {
    if (!Cookies.get("moToken")) {
      // redirect("/choose-creation-way");
      navigation("/");
    } else {
      dispatch(getListData(pageCount));
    }
  }, [pageCount]);

  // useEffect(() => {
  //   setActive(sessionStorage.getItem("activeList"));
  // }, [sessionStorage.getItem("activeList")]);
  useEffect(() => {
    setActive(params.id ? params.id : "1");
  }, [params.id]);

  useMemo(() => getList, []);
  const handleSwitchComponents = (event) => {
    setActive(event.target.id);
    navigation("/list-section/" + event.target.id);
  };

  return (
    <>
      <section className={`d-flex sectionsContainers`}>
        <TestSideNav />
        <div className="m-0 pages-container section-container">
          <NavBar />
          <div className="d-flex">
            {getList.loading ? (
              <Loading />
            ) : (
              <div className="bg">
                <div className="d-flex px-5">
                  <div className={`col-2 ${style.btnContainer}`}></div>
                  <div className={` col-8 mt-4 text-center `}>
                    <button
                      id={"1"}
                      className={
                        active === "1"
                          ? `${style.active}`
                          : `${style.btnControl}`
                      }
                      onClick={handleSwitchComponents}
                    >
                      Create List
                    </button>
                    <button
                      id={"2"}
                      className={
                        active === "2"
                          ? `${style.active}`
                          : `${style.btnControl}`
                      }
                      onClick={handleSwitchComponents}
                    >
                      View List
                    </button>
                  </div>
                  <div className={`col-2 ${style.btnContainer}`}></div>
                </div>
                {active === "1" ? (
                  <CreateList getList={getList.listData} />
                ) : (
                  <ViewList
                    getList={getList.listData}
                    setPageCount={setPageCount}
                    pageCount={pageCount}
                  />
                )}
              </div>
            )}
            <RechargeNav />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CreateListContainer;
