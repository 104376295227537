import React, { useEffect, useState, useMemo } from "react";
import style from "../../styles/pageStyle/ListStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getListData } from "../../state/listData/listAction";
import Loading from "../Loading";
import CheckBox from "./CheckBox";
import Controller from "../Controller";
import PaginationBar from "../PaginationBar";

const ViewList = ({
  getList,
  fromCreateList,
  setPageCount,
  pageCount,
  setSelectedListContainer,
}) => {
  const [historyData, setHistoryData] = useState(getList?.data?.items);
  const [listIDS, setListIDS] = useState([]);
  const [state, setState] = useState({
    List: historyData,
    MasterChecked: false,
    SelectedList: [],
  });

  useEffect(() => {
    setState({ ...state, List: historyData });
  }, [historyData]);

  useEffect(() => {
    let addingSelectToArray = state?.List?.map((x) => ({
      ...x,
      selected: false,
    }));

    setState({
      ...state,
      List: addingSelectToArray,
    });
  }, []);

  const onMasterCheck = (e) => {
    let tempList = state.List;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));
    // let selectedFromList = state?.List?.filter((e) => e.selected);
    // setSelectedListContainer(selectedFromList);
    //Update State
    setState({
      MasterChecked: e.target.checked,
      List: tempList,
      SelectedList: state?.List?.filter((e) => e.selected),
    });

    let selectedFromList = state?.List?.filter((e) => e.selected);
    let selectedFromListId = selectedFromList.map((ids) => ids.id);
    setListIDS(selectedFromListId);
    if (fromCreateList) {
      setSelectedListContainer(selectedFromListId);
    }
  };

  const onItemCheck = (e, item) => {
    let tempList = state.List;
    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = state?.List?.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    setState({
      MasterChecked: totalItems === totalCheckedItems,
      List: tempList,
      SelectedList: state?.List?.filter((e) => e.selected),
    });

    let selectedFromList = state?.List?.filter((e) => e.selected);
    let selectedFromListId = selectedFromList.map((ids) => ids.id);
    setListIDS(selectedFromListId);
    if (fromCreateList) {
      setSelectedListContainer(selectedFromListId);
    }
  };

  return (
    <>
      <div className={`${fromCreateList ? "" : "mt-5 px-5"}`}>
        {fromCreateList ? null : (
          <>
            <div className="mb-5">
              <h1>Contacts Lists</h1>
            </div>
            <Controller
              historyData={historyData}
              setHistoryData={setHistoryData}
              campaignsData={getList?.data?.items}
              listIDS={listIDS}
              pageCount={pageCount}
            />
          </>
        )}
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={state.MasterChecked}
                  id="mastercheck"
                  onChange={(e) => onMasterCheck(e)}
                />
                <span className="mx-2">List Name</span>
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col" className={`${style.contactsCount}`}>
                Contacts Count
              </th>
            </tr>
          </thead>
          <tbody>
            {state?.List?.map((user) => (
              <tr key={user.id} className={user.selected ? "selected" : ""}>
                <th scope="row">
                  <input
                    type="checkbox"
                    checked={user.selected}
                    className="form-check-input"
                    id="rowcheck{user.id}"
                    onChange={(e) => onItemCheck(e, user)}
                  />
                  <span className="mx-2">{user.collectionName}</span>
                </th>
                <td></td>
                <td></td>
                <td className="text-center">{user.numberOfCollection}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-center">
          <PaginationBar
            totalPageCount={getList?.data?.totalPages}
            historyData={historyData}
            setHistoryData={setHistoryData}
            setPageCount={setPageCount}
            pageCount={pageCount}
          />
        </div>
      </div>
    </>
  );
};

export default ViewList;
