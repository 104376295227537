import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extraReducers } from "./senderAction";
const initialState = { senderData: [], loading: false, error: null }

const senderSlice = createSlice({
    name: 'sender',
    initialState,
    reducers: {},
    extraReducers
})

export default senderSlice.reducer;