import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { editCampaign } from '../endpoints'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Cookies from "js-cookie";

const MySwal = withReactContent(Swal);
export const postEditCampaign = createAsyncThunk('edit/postEditCampaign', async (obj) => {
    const config = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('moToken')}`
        },
        url: editCampaign,
        data: obj
    }
    const response = axios(config).then((res) => {
        console.log('inside fullsss', res);

        return res
    }).catch((error) => {
        console.log('error', error.response.data);
    })
    return response
})

export const extraReducers = {
    [postEditCampaign.pending]: (state) => {
        state.loading = true
        state.error = null
    },
    [postEditCampaign.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.editData = payload.data
        console.log('inside full');
        MySwal.fire({
            icon: "success",
            text: payload.data.message
        });
    },
    [postEditCampaign.rejected]: (state, { error }) => {
        state.loading = false
        state.error = error
    },
}